<template>
  <div v-if="data.return_method === $options.returnMethod.SPECIAL_DELIVERY_CONTAINER">
    <ReturnContainerSelect
      :selected="data.return_method_id"
      @change="updateLine({ return_method_id: $event })"
    />
  </div>
</template>

<script>
import { ReturnMethod } from '@types/Rma';

import ReturnContainerSelect from '@scenes/SelfserviceScene/components/RmaLinePicker/components/ReturnContainerSelect';

export default {
  name: 'ReturnMethod',

  components: { ReturnContainerSelect },

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  methods: {
    updateLine(updateData) {
      this.rmaContainer.updateLine({ id: this.data.id, ...updateData });
    },
  },
  returnMethod: ReturnMethod,
  inject: ['rmaContainer'],
};
</script>
