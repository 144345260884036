<template>
  <div class="RmaHead">
    <div class="RmaHead__main">
      <div class="RmaHead__header">
        <h1 class="RmaHead__heading">Returnering</h1>
        <p class="RmaHead__subheading">{{ rmaContainer.rma.id }}</p>
      </div>
      <div class="RmaHead__events">
        <Timeline
          v-if="rmaContainer.rma.events && rmaContainer.rma.events.length > 0"
          :events="rmaContainer.rma.events"
          :show-full-date="true"
        />
      </div>
    </div>

    <div class="RmaHead__sidebar">
      <VueBarcode
        v-if="rmaContainer.rma.status === rmaContainer.statuses.CLOSED"
        class="RmaHead__barcode"
        :value="rmaContainer.rma.id"
        :options="{ displayValue: false }"
      />
      <Details :data="details" class="RmaHead__details" />
      <div class="RmaHead__comments Comments">
        <span v-if="rmaContainer.rma.comments.length > 0" class="Comments__header"
          >Kommentarer</span
        >
        <Comments :comments="rmaContainer.rma.comments" :enable-input="true" />
      </div>
    </div>
  </div>
  <!--
        <div class="RmaHead">
          <div class="RmaHead__information">
            <p
              v-if="rma.status === $options.rmaStatus.CLOSED"
              class="color-prim-brand ReturnIdWrapper"
            >
              <b class="ScreenOnly">{{ $t('rma.returns.return_id') }}:</b><br class="ScreenOnly" />
              <span class="ReturnId">{{ rma.id }}</span>
            </p>
            <p>
              <b>{{ $t('rma.returns.created') }}:</b><br />
              <Time v-if="rma.created" :datetime="rma.created" :show-time="true" />
            </p>
            <p>
              <b>{{ $t('rma.returns.updated') }}:</b><br />
              <Time v-if="rma.updated" :datetime="rma.updated" :show-time="true" />
            </p>
            <p>
              <b>{{ $t('rma.returns.quantity') }}:</b><br />
              {{ getQuantity(rma.lines) }}
            </p>
          </div>

          
        </div>
    -->
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode';
import Comments from './components/Comments';
import Timeline from '@scenes/SelfserviceScene/components/Timeline';
import Details, { Formats } from '@scenes/SelfserviceScene/components/Details';
import RmaStatusLabel from '@scenes/SelfserviceScene/components/RmaStatusLabel';

export default {
  name: 'RmaHead',

  components: {
    Timeline,
    Comments,
    VueBarcode,
    Details,
  },

  computed: {
    details() {
      return [
        {
          header: this.$t('rma.details.rma_id'),
          label: this.rmaContainer.rma.id,
        },
        {
          header: 'Status',
          template: RmaStatusLabel,
          templateProps: {
            solid: true,
            status: this.rmaContainer.rma.status,
            events: this.rmaContainer.rma.events,
          },
        },
        {
          header: this.$t('rma.details.customer_name'),
          label: this.rmaContainer.rma.customer && this.rmaContainer.rma.customer.company_name,
        },
        {
          header: this.$t('rma.details.created'),
          label: this.rmaContainer.rma.created,
          format: Formats.DATE,
        },
        {
          header: this.$t('rma.details.return_method_ids'),
          label: this.rmaContainer.getReturnMethodIds
            .filter(methodId => methodId !== 'other')
            .join(', '),
        },
      ];
    },
  },

  methods: {
    openCommentDialog() {
      this.$refs.addCommentDialogue.open();
    },
  },

  inject: {
    rmaContainer: {
      default() {
        console.error('Missing RmaContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.RmaHead {
  display: flex;

  .RmaHead__heading {
    font-size: 30px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0.2em;
  }

  .RmaHead__subheading {
    color: #a8a8a8;
    font-size: 16px;
    display: none;
  }

  .RmaHead__header {
    margin-bottom: 40px;
  }

  .RmaHead__main {
    flex: 1.2;
  }

  .RmaHead__sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .RmaHead__barcode {
    display: none;
    width: 280px;
    margin-bottom: 20px;
  }
  .RmaHead__details {
    margin-bottom: 20px;
  }
}

.Comments {
  width: 100%;
  margin-bottom: 20px;
  .Comments__header {
    display: block;

    color: #666666;
    margin-bottom: 0.6em;
  }
}

@mixin printStyles() {
  .ScreenOnly {
    visibility: hidden !important;
  }
  .RmaHead {
    .RmaHead__heading {
      margin-bottom: 0;
      font-size: 20px;
    }

    .RmaHead__subheading {
      font-size: 55px;
      display: block;
    }

    .RmaHead__messages {
      display: none;
    }
    .RmaHead__barcode {
      display: block !important;
    }
  }
}

.ForcedPrint {
  @include printStyles();
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
