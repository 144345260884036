<template>
  <StatusLabel :solid="solid" :type="type">
    <template #icon> </template>
    <template #default>
      {{ statusMessage }}
    </template>
  </StatusLabel>
</template>

<script>
import { StatusLabel, types as labelTypes } from '../StatusLabel';
import { RmaStatus } from '@types/Rma';
import { eventTypes } from '@types/Event';
export default {
  name: 'RmaStatusLabel',
  components: {
    StatusLabel,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    solid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      if (this.events.length === 0) {
        switch (this.status) {
          case RmaStatus.OPEN:
            return labelTypes.TRACE;
          case RmaStatus.CLOSED:
            return labelTypes.WARNING;
          case RmaStatus.DELETED:
            return labelTypes.ERROR;
          default:
            return '';
        }
      } else {
        switch (this.events[0].type) {
          case eventTypes.FINAL:
            return labelTypes.SUCCESS;
          case eventTypes.INTERNAL:
            return labelTypes.INFO;
          case eventTypes.INITIAL:
            return labelTypes.INFO;
          default:
            return '';
        }
      }
    },
    statusMessage() {
      if (this.events && this.events.length > 0) return this.events[0].text;
      else return this.$t('rma.status.' + this.status);
    },
  },
};
</script>
