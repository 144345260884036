<template>
  <Modal ref="addCommentDialogue" title="Tilføj kommentar">
    <template #default>
      <Textarea
        :value="commentText"
        :action="value => (commentText = value)"
        placeholder="Angiv en kommentar..."
        :validation-action="valid => (commentIsValid = valid)"
        :auto-height="true"
      />
    </template>
    <template #controls>
      <Button :size="ButtonSizes.SMALL" @click="upsertComment">
        Tilføj
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Modal } from '@components/Modal';
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import Textarea from '@components/Textarea';

export default {
  name: 'CommentModal',

  components: {
    Button,
    Modal,
    Textarea,
  },

  enums: {
    ButtonSizes,
  },

  props: {
    lineId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      commentText: '',
      commentIsValid: false,
    };
  },

  methods: {
    open() {
      this.$refs.addCommentDialogue.open();
    },
    close() {
      this.$refs.addCommentDialogue.close();
    },
    upsertComment() {
      if (this.lineId) {
        this.rmaContainer.upsertLineComment({
          rma_line_id: this.lineId,
          comment: this.commentText,
        });
      } else {
        this.rmaContainer.upsertComment({
          comment: this.commentText,
        });
      }
      // Clear commenText on upsert and close dialogue
      this.commentText = '';
      this.$refs.addCommentDialogue.close();
    },
  },

  inject: {
    rmaContainer: {
      default() {
        console.error('Missing RmaContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.RmaHead {
  display: flex;

  &__heading {
    font-size: 30px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0.2em;
  }

  &__subheading {
    color: #a8a8a8;
    font-size: 16px;
  }

  &__main {
    flex: 1.5;
  }

  &__messages {
    flex: 1;
  }

  .Messages {
    background: $color-prim-grey-lighter;

    &__main {
      padding: 20px;
      min-height: 300px;
    }

    &__add {
      padding: 20px;
      border-top: 1px solid $color-prim-grey;
      text-align: center;
      text-decoration: underline;
      color: grey;

      i {
        margin-left: 0.5em;
      }
    }

    &__title {
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
}

.CommentWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;

  .Textarea {
    flex-grow: 1;
  }

  &__content {
    width: 100%;
    margin-bottom: 20px;
    align-self: flex-end;
  }

  &__bottom {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    align-self: flex-end;
  }

  &__addButton {
    margin-left: 20px;
  }

  &__comment-image {
    float: left;
    border-radius: 100%;
    height: 40px;
  }

  &__comment-author {
    margin-left: 50px;
    font-size: 0.9em;
    margin-bottom: 5px;
  }

  &__comment {
    margin-bottom: 20px;
    min-width: 200px;
    max-width: 75%;
    display: table;
    clear: both;

    &--right {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__comment-date {
    text-align: right;
    color: $color-off-grey;
    font-size: 0.8em;
  }

  &__comment-message {
    padding: 15px;
    border-radius: 0px 15px 15px 15px;
    font-size: 1em;
    margin-bottom: 4px;
    color: $color-white;

    &--right {
      border-radius: 15px 0px 15px 15px;
    }

    &--author-margin {
      margin-left: 50px;
    }
  }
}
</style>
