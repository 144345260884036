<template>
  <div>
    <CommentModal ref="addCommentDialogue" :line-id="data.id" />
    <div class="AddComment">
      <Button
        :size="buttonSizes.EXTRA_SMALL"
        :color="buttonColors.WHITE"
        :icon="buttonIcons.PEN"
        @click="openModal"
        >Tilføj kommentar
      </Button>
    </div>
  </div>
</template>

<script>
import CommentModal from '../../../../../CommentModal';
import {
  Button,
  sizes as buttonSizes,
  colors as buttonColors,
  icons as buttonIcons,
} from '@components/Buttons';

export default {
  name: 'Comments',

  components: {
    CommentModal,
    Button,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  created() {
    this.buttonSizes = buttonSizes;
    this.buttonColors = buttonColors;
    this.buttonIcons = buttonIcons;
  },

  methods: {
    openModal() {
      this.$refs.addCommentDialogue.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.AddComment {
  text-align: left;
  font-size: 14px;
  text-decoration: underline;
}
</style>
