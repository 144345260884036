<template>
  <div v-if="!data.product">Ingen beskrivelse af produkt...</div>
  <div v-else class="Product">
    <ProductLineDetails class="Product__details" :data="data" />

    <div>
      <div v-if="data.comments && data.comments.length > 0" class="Comments">
        <span>Kommentarer:</span>
        <CommentsList :comments="data.comments" :size="commentListSize.SMALL" />
      </div>

      <Comments :data="data" :index="index" />
    </div>
  </div>
</template>

<script>
import Comments from './components/Comments';
import CommentsList, { size } from '@scenes/SelfserviceScene/components/CommentsList';
import Time from '@components/Time';

import settings from '@settings';

import ProductLineDetails from '@scenes/SelfserviceScene/components/ProductLineDetails';

export default {
  name: 'Product',

  components: {
    Time,
    CommentsList,
    Comments,
    ProductLineDetails,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    getTitle() {
      if (this.data.product.title === undefined || this.data.product.title === '') {
        return this.data.product.item_reference ?? '';
      }

      return this.data.product.title;
    },
  },

  created() {
    this.commentListSize = size;
    this.settings = settings;
  },

  inject: ['rmaContainer'],
};
</script>

<style lang="scss" scoped>
.Comments {
  display: grid;
  grid-gap: 7px;
  font-size: 12px;
  margin-top: 20px;
  max-width: 400px;
}
.Product__details {
  margin-bottom: 0.5em;
}
@mixin printStyles() {
  .ScreenOnly {
    visibility: hidden !important;
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
