<template>
  <div class="MessageInput">
    <textarea
      v-model="currentValue"
      :style="inputStyle"
      placeholder="Skriv en kommentar"
      @keyup="resize"
    ></textarea>
    <textarea
      ref="shadow"
      v-model="currentValue"
      class="MessageInput__shadow"
      tabindex="0"
    ></textarea>
    <button class="MessageInput__sendButton" @click="$emit('send', currentValue)">
      <i class="fas fa-chevron-right color-prim-brand"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'MessageInput',
  props: {},
  data() {
    return {
      currentValue: '',
      inputHeight: '0',
    };
  },
  computed: {
    inputStyle() {
      return {
        'min-height': this.inputHeight,
      };
    },
  },
  watch: {},
  mounted() {
    this.resize();
  },
  methods: {
    clear() {
      this.currentValue = '';
      this.resize();
    },
    resize() {
      this.inputHeight = `${this.$refs.shadow.scrollHeight}px`;
    },
  },
};
</script>

<style scoped lang="scss">
.MessageInput {
  position: relative;
  textarea {
    margin: 0;
    outline: 0;
    box-shadow: none;
    background: none;
    border: 1px solid $color-input-border;
    font-size: inherit;
    border-radius: 0.1em;
    //border-radius: 0;
    padding: 1em;
    padding-right: 3em;
    color: $color-input-border;
    width: 100%;
    transition: min-height 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out,
      box-shadow 200ms ease-out;

    resize: none;
    overflow: hidden;
    font-size: 14px;
    height: 0;

    &.MessageInput__shadow {
      max-height: 0;
      pointer-events: none;
      opacity: 0;
      margin: 0;
    }
  }
  textarea:focus {
    color: #333333;
    border-color: #333333;
    box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.6), 0px 1px 10px rgba(0, 82, 156, 0.4);
  }
  .MessageInput__sendButton {
    display: flex;
    position: absolute;
    width: 47px;
    height: 47px;
    font-size: 1.4em;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background: none;
    transition: text-shadow 90ms;
    &:hover {
      text-shadow: 0 0 20px black;
    }
  }
}
</style>
