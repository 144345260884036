<template>
  <div :class="['CommentsList']">
    <CommentModal ref="addCommentDialogue" />
    <div v-if="comments.length > 0" class="CommentsList__comments">
      <template v-for="comment in sortByDateProp(comments, 'created')">
        <Comment
          v-if="comment.comment_author === 'customer'"
          :key="'comment' + comment.id"
          :is-author="true"
          :message="comment.message"
          :date="comment.created"
          class="CommentsList__comment"
        />
        <Comment
          v-else
          :key="'comment' + comment.id"
          :is-author="false"
          :author="{
            name: 'AD Danmark kundeservice',
            picture: vauLogo,
          }"
          :message="comment.message"
          :date="comment.created"
          class="CommentsList__comment"
        />
      </template>
    </div>
    <div v-if="enableInput" class="CommentsList__input">
      <Button
        :size="buttonSizes.EXTRA_SMALL"
        :color="buttonColors.WHITE"
        :icon="buttonIcons.PEN"
        @click="openModal"
        >Tilføj kommentar
      </Button>
    </div>
  </div>
</template>

<script>
import vauLogo from './assets/vau-logo.png';
import CommentModal from '@scenes/SelfserviceScene/scenes/RmaScene/components/CommentModal';
import helpers from '@helpers';
import { RmaStatus } from '@types/Rma';
import {
  Button,
  sizes as buttonSizes,
  colors as buttonColors,
  icons as buttonIcons,
} from '@components/Buttons';
import Time from '@components/Time';
import Comment from './components/Comment';
import MessageInput from './components/MessageInput';
export const size = {
  SMALL: 'small',
  REGULAR: 'regular',
};

export default {
  name: 'CommentsList',

  components: {
    Button,
    Time,
    Comment,
    CommentModal,
  },

  props: {
    comments: {
      requred: true,
      type: Array,
      default: [],
    },
    size: {
      type: String,
      required: false,
      default: size.REGULAR,
    },
    enableInput: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      commentInput: '',
      vauLogo,
    };
  },

  created() {
    this.commentListSize = size;

    this.buttonIcons = buttonIcons;
    this.buttonSizes = buttonSizes;
    this.buttonColors = buttonColors;
  },

  methods: {
    sortByDateProp: helpers.manipulation.sortByDateProp,

    openModal() {
      this.$refs.addCommentDialogue.open();
    },
  },
  inject: ['rmaContainer'],
};
</script>

<style lang="scss" scoped>
.CommentsList {
  .CommentsList__input {
  }
  .CommentsList__comment {
    margin-bottom: 1em;
  }
  .CommentsList__comments {
    margin-bottom: 2em;
  }
}
</style>
