<template>
  <div class="RmaLines">
    <div class="RmaLines__controlsBar">
      <slot name="controls" />
    </div>
    <Table v-if="lines.length" itemKey="id" :items="lines">
      <TableColumn v-slot="{ item }" header="Antal">
        {{ item.quantity }}
      </TableColumn>
      <TableColumn v-slot="{ item }" header="Oprettet">
        <Product :data="item" :index="0" />
      </TableColumn>
      <TableColumn v-slot="{ item }" header="Fakturanummer">
        {{ item.invoice_number }}
      </TableColumn>
      <TableColumn v-if="rma.status === RmaStatus.OPEN" v-slot="{ item }" header="Retur id">
        <ReturnMethod :data="item" :index="0" />
      </TableColumn>
      <TableColumn v-if="rma.status === RmaStatus.OPEN" v-slot="{ item }">
        <Delete :data="item" :index="0" />
      </TableColumn>
    </Table>
  </div>
</template>

<script>
import { Table, TableColumn } from '@components/Table';
import Delete from './components/Delete';
import ReturnMethod from './components/ReturnMethod';
import Product from './components/Product';

import { RmaStatus } from '@types/Rma';

export default {
  name: 'RmaLines',

  components: {
    Table,
    TableColumn,
    ReturnMethod,
    Product,
    Delete,
  },

  enums: {
    RmaStatus,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    lines: {
      type: Array,
      required: true,
    },
    rma: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.RmaLines {
  .RmaLines__controlsBar {
    width: 100%;
    text-align: end;
  }
}
</style>
