<template>
  <RmaContainer
    ref="rmaContainer"
    v-slot="{ rma, lines, status, draftLinesQuantity, returnMethodIds }"
  >
    <div :class="['RmaScene', print && 'ForcedPrint']">
      <Modal ref="confirmDeleteDialog" title="Slet returnering?">
        <template #default>
          <p>Er du sikker på du vil slette denne returnering.</p>
        </template>
        <template #controls="{ close }">
          <Button type="light" @click="close">Fortryd</Button>
          <Button :color="ButtonColors.RED" :icon="ButtonIcons.TRASH" @click="deleteRma">
            Slet
          </Button>
        </template>
      </Modal>
      <Validator v-slot="{ valid, showError }">
        <Modal
          ref="addDialog"
          title="Find varer"
          :size="ModalSizes.LARGE"
          @close="onAddDialogClose"
        >
          <template #default>
            <ProgressOverlay :progress="submittingLines">
              <RmaLinePicker />
            </ProgressOverlay>
          </template>
          <template #controls>
            <span>{{ $tc('itemsSelected', draftLinesQuantity, { n: draftLinesQuantity }) }}</span>
            <Button
              :disabled="draftLinesQuantity === 0"
              :working="submittingLines"
              @click="valid ? submitDraftLines() : showError()"
            >
              Tilføj
            </Button>
          </template>
        </Modal>
      </Validator>
      <ProgressOverlay
        :progress="
          status === RmaStates.LOADING ||
            status === RmaStates.CLOSING ||
            status === RmaStates.UPDATING
        "
        :hide-content-on-progress="status === RmaStates.UPDATING ? false : true"
      >
        <PageBar
          class="PageBar"
          :controls="[
            rma.status === $options.rmaStatus.CLOSED && {
              name: 'Print',
              action: printPage,
              icon: ButtonIcons.PRINT,
            },
            rma.status !== $options.rmaStatus.CLOSED && {
              name: 'Slet',
              action: openConfirmDeleteDialog,
              icon: ButtonIcons.TRASH,
            },
          ]"
        />
        <RmaHead v-if="rma.status" class="RmaHead" />

        <template v-if="lines.length === 0">
          <div class="NoAddedLines">
            <div class="NoAddedLines__text">
              Sagen er oprettet, du skal nu vælge de varer som du ønsker at returnere.
            </div>
            <Button type="large" :icon="ButtonIcons.ADD" @click="openAddDialog">Tilføj vare</Button>
          </div>
        </template>
        <template v-else>
          <template
            v-if="
              rma.status === $options.rmaStatus.CLOSED && $options.settings.organization === 'VAU'
            "
          >
            <div v-for="methodId in returnMethodIds" :key="methodId">
              <RmaLines
                class="RmaScene__lines"
                :title="returnMethodIds.length > 1 ? getReturnMethodTitle(methodId) : ''"
                :lines="getLinesByReturnMethodID(lines, methodId)"
                :rma="rma"
              />
            </div>
          </template>
          <template v-else>
            <RmaLines class="RmaScene__lines" title="Varer" :lines="lines" :rma="rma">
              <template #controls>
                <Button
                  v-if="rma.status === $options.rmaStatus.OPEN && lines.length > 0"
                  class="AddItem"
                  :size="ButtonSizes.SMALL"
                  :icon="ButtonIcons.ADD"
                  @click="openAddDialog"
                >
                  Tilføj vare
                </Button>
              </template>
            </RmaLines>
          </template>

          <div class="ButtonsWrapper">
            <Button
              v-if="rma.status === $options.rmaStatus.OPEN"
              type="cta"
              :icon="ButtonIcons.SEND"
              @click="openConfirmSubmitDialog"
            >
              Indsend
            </Button>
          </div>

          <Modal ref="confirmSubmitDialog" title="Returnering">
            <template #default>
              <p>Er alt som det skal være?</p>
            </template>
            <template #controls="{ close }">
              <Button type="light" @click="close">Fortryd</Button>
              <Button type="cta" @click="submitRma">Godkend</Button>
            </template>
          </Modal>
        </template>
      </ProgressOverlay>
    </div>
  </RmaContainer>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode';
import settings from '@settings';
import { RmaStates, RmaStatus } from '@types/Rma';
import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';
import RmaLinePicker from '@scenes/SelfserviceScene/components/RmaLinePicker';
import RmaContainer from '@scenes/SelfserviceScene/containers/RmaContainer';
import PageBar from '@scenes/SelfserviceScene/components/PageBar';
import { Modal, Sizes as ModalSizes } from '@components/Modal';
import Textarea from '@components/Textarea';
import {
  Button,
  sizes as ButtonSizes,
  colors as ButtonColors,
  icons as ButtonIcons,
} from '@components/Buttons';
import Time from '@components/Time';
import ProgressOverlay from '@components/ProgressOverlay';
import Validator from '@containers/Validator';
import RmaLines from './components/RmaLines';
import RmaHead from './components/RmaHead';

export default {
  name: 'RmaScene',

  components: {
    RmaHead,
    PageBar,
    RmaLines,
    Button,
    SelfservicePageLayout,
    RmaContainer,
    RmaLinePicker,
    Modal,
    Textarea,
    ProgressOverlay,
    VueBarcode,
    Time,
    Validator,
  },

  enums: {
    RmaStates,
    ButtonSizes,
    ButtonColors,
    ButtonIcons,
    ModalSizes,
  },

  props: {
    print: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      submittingLines: false,
    };
  },

  mounted() {
    this.$refs.rmaContainer.changeRma(this.$route.params.id);
  },

  methods: {
    getReturnMethodTitle(methodId) {
      if (methodId === 'other') {
        return this.$t('other');
      } else {
        return methodId ? 'Kasse ' + methodId : '';
      }
    },
    onAddDialogClose() {
      this.$refs.rmaContainer.clearDraftLines();
    },
    openAddDialog() {
      this.$refs.addDialog.open();
    },
    closeAddDialog() {
      this.$refs.addDialog.close();
    },
    openConfirmSubmitDialog() {
      this.$refs.confirmSubmitDialog.open();
    },
    openConfirmDeleteDialog() {
      this.$refs.confirmDeleteDialog.open();
    },
    printPage() {
      window.print();
    },
    getQuantity(data) {
      const sumTotal = arr => {
        return arr === undefined ? 0 : arr.reduce((sum, { quantity }) => sum + quantity, 0);
      };

      return sumTotal(data);
    },
    getMethodIdsFromLines(lines) {
      return [...new Set(lines.map(line => line.return_method_id || 'other'))]; // "" will be merged with "other".
    },
    getLinesByReturnMethodID(lines, methodId) {
      return lines.filter(line => (line.return_method_id || 'other') === methodId);
    },
    deleteRma() {
      this.$refs.confirmDeleteDialog.close();
      this.$refs.rmaContainer
        .deleteRma()
        .then(rma => {
          this.$router.push({ name: '/selfservice/returns' }); // redirect to the print layout on submit
        })
        .catch(() => {
          console.log('FAILED');
        });
    },
    submitRma() {
      this.$refs.confirmSubmitDialog.close();
      this.$refs.rmaContainer
        .submitRma()
        .then(rma => {
          this.$router.push({ name: this.$route.name + '/print', params: { id: rma.id } }); // redirect to the print layout on submit
        })
        .catch(() => {
          console.log('FAILED');
        });
    },
    submitDraftLines() {
      this.submittingLines = true;
      this.$refs.rmaContainer.submitDraftLines().then(() => {
        this.submittingLines = false;
        this.closeAddDialog();
      }); // TODO: Error handling!
    },
  },

  rmaStatus: RmaStatus,
  settings,
};
</script>

<style lang="scss" scoped>
.RmaScene {
  padding: 0 20px;

  @media (min-width: $tablet) {
    padding: 0;
  }

  .RmaScene__lines {
    margin-bottom: 20px;
  }
}

.HiddenLayout {
  opacity: 0;
}

.ButtonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.ReturnId {
  font-size: 1.45em;
  font-weight: bold;
}

.NoAddedLines {
  text-align: center;

  &__text {
    margin-bottom: 20px;
  }
}

.RmaHead {
  margin-bottom: 50px;
}

@mixin printStyles() {
  .ScreenOnly {
    visibility: hidden !important;
  }
  .RmaHead {
    &__barcode {
      display: block;
    }
  }

  .ReturnIdWrapper {
    flex: 100%;
  }

  .ReturnId {
    font-size: 6em;
  }

  .AddItem,
  .ButtonsWrapper {
    display: none;
  }
}

@media print {
  @include printStyles();
  .PageBar {
    display: none;
  }
}

.ForcedPrint {
  @include printStyles();
}
</style>
