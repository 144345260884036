<template>
  <div
    :class="[
      'Textarea',
      size === TextareaSize.SMALL && 'Textarea--small',
      textareaValidated === false && initiated && 'Textarea--not-validated',
    ]"
  >
    <div class="TextareaWrapper">
      <div v-if="disabled" class="TextOnly" v-html="convertNewLines(value)" />

      <textarea
        v-if="!disabled"
        :tabIndex="0"
        :value="value"
        :placeholder="placeholder"
        ref="textarea"
        :class="[autoHeight && 'AutoHeight']"
        @input="
          e => {
            initiateInput();
            action(e.target.value);
            calcAutoheight();
          }
        "
        @blur="
          e => {
            // If blurAction is undefined, run action istead - if it's defined
            if (blurAction === undefined && action !== undefined) {
              action(e.target.value);
            } else if (blurAction !== undefined) {
              // If both blurAction and action are undefined, then we shouldn't run this
              // otherwise, default to bluraction
              blurAction(e.target.value);
            }
          }
        "
      />
    </div>
    <div
      v-if="!textareaValidated && initiated"
      :class="['ErrorWrapper', tight && 'ErrorWrapper--tight']"
    >
      {{ validationErrorMessage }}
    </div>
  </div>
</template>

<script>
import settings from '@settings';
import helpers from '@helpers';

export const TextareaSize = {
  REGULAR: 'REGULAR',
  SMALL: 'SMALL',
};

export default {
  name: 'Textarea',

  props: {
    value: {
      required: true,
    },
    size: {
      required: false,
      default: TextareaSize.REGULAR,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    action: {
      required: false,
      default: value => {
        if (settings.testmode) {
          console.log(
            'Remember to apply a method on the "action" prop for handling value change. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },
    blurAction: {
      required: false,
      default: value => {
        if (settings.testmode) {
          console.log(
            'Method on prop "blurAction" not defined. Defaulting to "action" prop. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },
    validationAction: {
      required: false,
      // Definition:
      // ---------------------
      // default: state => {
      //   console.log(state);
      // },
    },

    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },

    required: {
      required: false,
      default: false,
    },
    pattern: {
      required: false,
      default: '',
      type: String,
    },
    patternErrorMessage: {
      required: false,
      default: '',
      type: String,
    },
    autoHeight: {
      required: false,
      default: false,
      type: Boolean,
    },

    minHeight: {
      default: 100,
      type: Number,
    },

    maxHeight: {
      default: 250,
      type: Number,
    },
    tight: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isSelected: false,
      TextareaSize,
      textareaValidated: false,
      initiated: false,
    };
  },

  computed: {
    validationErrorMessage() {
      const { value, required, patternErrorMessage } = this;

      if (value === '' && required) {
        return 'Du skal indtaste en en værdi i feltet...';
      }

      if (patternErrorMessage !== '') {
        return patternErrorMessage;
      }
    },
  },

  watch: {
    value(newValue) {
      // Validate on changed value
      this.validationActionWrapper();

      // If autoheight
      this.calcAutoheight();
    },
  },

  mounted() {
    // Initially validate textarea
    this.validationActionWrapper();

    // Set height
    this.calcAutoheight();
  },

  methods: {
    initiateInput() {
      if (this.initiated === false) {
        this.initiated = true;
      }
    },
    calcAutoheight() {
      if (this.autoHeight) {
        var el = this.$refs.textarea;

        setTimeout(() => {
          el.style.cssText = `height:${this.minHeight}px; padding:0`;

          // 20px is the padding of 9.8 x 2
          const height = el.scrollHeight + 20;

          if (height > this.maxHeight) {
            el.style.cssText = 'height:' + this.maxHeight + 'px';
          } else if (height < this.minHeight) {
            el.style.cssText = 'height:' + this.minHeight + 'px';
          } else {
            el.style.cssText = 'height:' + height + 'px';
          }
        }, 0);
      }
    },
    convertNewLines(value) {
      return helpers.manipulation.stringNewLine(value);
    },

    validationActionWrapper() {
      // Set data prop validations status
      this.textareaValidated = this.validateTextarea();

      // Send the state back, if an action is provided
      if (this.validationAction !== undefined) {
        this.validationAction(this.textareaValidated);
      }
    },

    validateTextarea() {
      // Super nifty validation here
      const { value, required, type, pattern } = this;

      if (value === '' && required) {
        return false;
      }

      if (pattern !== '') {
        if (pattern.test(value) === false) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  color: $color-black;

  margin: 0;
  outline: 0;
  box-shadow: none;
  background: none;
  border: 1px solid #999999;
  font-size: inherit;
  border-radius: 0.2em;
  padding: 0.7em 0.7em 0.7em 0.7em;
  color: #999999;
  width: 100%;
  transition: border-color 200ms ease-out, color 200ms ease-out, box-shadow 200ms ease-out;
  user-select: none;

  resize: vertical;

  vertical-align: top; // Removed weird spacing at the bottom of the textarea

  &:focus {
    color: #333333;
    border-color: #333333;
    box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.6), 0px 1px 10px rgba(0, 82, 156, 0.4);
  }
}

.TextOnly {
  border: 1px solid #999999;
  color: #333333;
  border-radius: 0.2em;
  padding: 0.7em 0.7em 0.7em 0.7em;
  min-height: 100px;
}

.TextareaWrapper {
  position: relative;
}

textarea[type='number']::-webkit-inner-spin-button,
textarea[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Textarea {
  margin-bottom: 0;

  &--small {
    font-size: 12px;
  }

  &--not-validated {
    color: red;
    textarea {
      border-color: red;
    }
  }
}

.AutoHeight {
  resize: none;
}

.ErrorWrapper {
  padding-left: 0.3em;
  padding-top: 0.4em;
  color: $color-input-error;
  font-size: 12px;

  &--tight {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: $color-input-error;
  }
}

@mixin printStyles() {
  .Textarea,
  textarea {
    border-color: transparent;
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
