var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RmaLines"},[_c('div',{staticClass:"RmaLines__controlsBar"},[_vm._t("controls")],2),(_vm.lines.length)?_c('Table',{attrs:{"itemKey":"id","items":_vm.lines}},[_c('TableColumn',{attrs:{"header":"Antal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}}],null,false,1293144132)}),_c('TableColumn',{attrs:{"header":"Oprettet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('Product',{attrs:{"data":item,"index":0}})]}}],null,false,3151098342)}),_c('TableColumn',{attrs:{"header":"Fakturanummer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice_number)+" ")]}}],null,false,3546088018)}),(_vm.rma.status === _vm.RmaStatus.OPEN)?_c('TableColumn',{attrs:{"header":"Retur id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('ReturnMethod',{attrs:{"data":item,"index":0}})]}}],null,false,703462168)}):_vm._e(),(_vm.rma.status === _vm.RmaStatus.OPEN)?_c('TableColumn',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('Delete',{attrs:{"data":item,"index":0}})]}}],null,false,3927987892)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }