<template>
  <Icon class="Header__control" @click="remove">
    <i class="far fa-trash-alt"></i>
  </Icon>
</template>

<script>
import { Icon } from '@components/Buttons';

export default {
  name: 'Delete',

  components: {
    Icon,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    remove() {
      this.rmaContainer.removeLine(this.data.id);
    },
  },
  inject: ['rmaContainer'],
};
</script>

<style lang="scss" scoped>
.Header__control {
  font-size: 1.2em;
}
</style>
