var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'Textarea',
    _vm.size === _vm.TextareaSize.SMALL && 'Textarea--small',
    _vm.textareaValidated === false && _vm.initiated && 'Textarea--not-validated' ]},[_c('div',{staticClass:"TextareaWrapper"},[(_vm.disabled)?_c('div',{staticClass:"TextOnly",domProps:{"innerHTML":_vm._s(_vm.convertNewLines(_vm.value))}}):_vm._e(),(!_vm.disabled)?_c('textarea',{ref:"textarea",class:[_vm.autoHeight && 'AutoHeight'],attrs:{"tabIndex":0,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function (e) {
          _vm.initiateInput();
          _vm.action(e.target.value);
          _vm.calcAutoheight();
        },"blur":function (e) {
          // If blurAction is undefined, run action istead - if it's defined
          if (_vm.blurAction === undefined && _vm.action !== undefined) {
            _vm.action(e.target.value);
          } else if (_vm.blurAction !== undefined) {
            // If both blurAction and action are undefined, then we shouldn't run this
            // otherwise, default to bluraction
            _vm.blurAction(e.target.value);
          }
        }}}):_vm._e()]),(!_vm.textareaValidated && _vm.initiated)?_c('div',{class:['ErrorWrapper', _vm.tight && 'ErrorWrapper--tight']},[_vm._v(" "+_vm._s(_vm.validationErrorMessage)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }