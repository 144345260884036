var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RmaContainer',{ref:"rmaContainer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rma = ref.rma;
var lines = ref.lines;
var status = ref.status;
var draftLinesQuantity = ref.draftLinesQuantity;
var returnMethodIds = ref.returnMethodIds;
return [_c('div',{class:['RmaScene', _vm.print && 'ForcedPrint']},[_c('Modal',{ref:"confirmDeleteDialog",attrs:{"title":"Slet returnering?"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',[_vm._v("Er du sikker på du vil slette denne returnering.")])]},proxy:true},{key:"controls",fn:function(ref){
var close = ref.close;
return [_c('Button',{attrs:{"type":"light"},on:{"click":close}},[_vm._v("Fortryd")]),_c('Button',{attrs:{"color":_vm.ButtonColors.RED,"icon":_vm.ButtonIcons.TRASH},on:{"click":_vm.deleteRma}},[_vm._v(" Slet ")])]}}],null,true)}),_c('Validator',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var showError = ref.showError;
return [_c('Modal',{ref:"addDialog",attrs:{"title":"Find varer","size":_vm.ModalSizes.LARGE},on:{"close":_vm.onAddDialogClose},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ProgressOverlay',{attrs:{"progress":_vm.submittingLines}},[_c('RmaLinePicker')],1)]},proxy:true},{key:"controls",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$tc('itemsSelected', draftLinesQuantity, { n: draftLinesQuantity })))]),_c('Button',{attrs:{"disabled":draftLinesQuantity === 0,"working":_vm.submittingLines},on:{"click":function($event){valid ? _vm.submitDraftLines() : showError()}}},[_vm._v(" Tilføj ")])]},proxy:true}],null,true)})]}}],null,true)}),_c('ProgressOverlay',{attrs:{"progress":status === _vm.RmaStates.LOADING ||
          status === _vm.RmaStates.CLOSING ||
          status === _vm.RmaStates.UPDATING,"hide-content-on-progress":status === _vm.RmaStates.UPDATING ? false : true}},[_c('PageBar',{staticClass:"PageBar",attrs:{"controls":[
          rma.status === _vm.$options.rmaStatus.CLOSED && {
            name: 'Print',
            action: _vm.printPage,
            icon: _vm.ButtonIcons.PRINT,
          },
          rma.status !== _vm.$options.rmaStatus.CLOSED && {
            name: 'Slet',
            action: _vm.openConfirmDeleteDialog,
            icon: _vm.ButtonIcons.TRASH,
          } ]}}),(rma.status)?_c('RmaHead',{staticClass:"RmaHead"}):_vm._e(),(lines.length === 0)?[_c('div',{staticClass:"NoAddedLines"},[_c('div',{staticClass:"NoAddedLines__text"},[_vm._v(" Sagen er oprettet, du skal nu vælge de varer som du ønsker at returnere. ")]),_c('Button',{attrs:{"type":"large","icon":_vm.ButtonIcons.ADD},on:{"click":_vm.openAddDialog}},[_vm._v("Tilføj vare")])],1)]:[(
            rma.status === _vm.$options.rmaStatus.CLOSED && _vm.$options.settings.organization === 'VAU'
          )?_vm._l((returnMethodIds),function(methodId){return _c('div',{key:methodId},[_c('RmaLines',{staticClass:"RmaScene__lines",attrs:{"title":returnMethodIds.length > 1 ? _vm.getReturnMethodTitle(methodId) : '',"lines":_vm.getLinesByReturnMethodID(lines, methodId),"rma":rma}})],1)}):[_c('RmaLines',{staticClass:"RmaScene__lines",attrs:{"title":"Varer","lines":lines,"rma":rma},scopedSlots:_vm._u([{key:"controls",fn:function(){return [(rma.status === _vm.$options.rmaStatus.OPEN && lines.length > 0)?_c('Button',{staticClass:"AddItem",attrs:{"size":_vm.ButtonSizes.SMALL,"icon":_vm.ButtonIcons.ADD},on:{"click":_vm.openAddDialog}},[_vm._v(" Tilføj vare ")]):_vm._e()]},proxy:true}],null,true)})],_c('div',{staticClass:"ButtonsWrapper"},[(rma.status === _vm.$options.rmaStatus.OPEN)?_c('Button',{attrs:{"type":"cta","icon":_vm.ButtonIcons.SEND},on:{"click":_vm.openConfirmSubmitDialog}},[_vm._v(" Indsend ")]):_vm._e()],1),_c('Modal',{ref:"confirmSubmitDialog",attrs:{"title":"Returnering"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',[_vm._v("Er alt som det skal være?")])]},proxy:true},{key:"controls",fn:function(ref){
          var close = ref.close;
return [_c('Button',{attrs:{"type":"light"},on:{"click":close}},[_vm._v("Fortryd")]),_c('Button',{attrs:{"type":"cta"},on:{"click":_vm.submitRma}},[_vm._v("Godkend")])]}}],null,true)})]],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }