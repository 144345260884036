<template>
  <div :class="['Comment', isAuthor && 'Comment--owner']">
    <div v-if="author && author.picture" :class="['Comment__avatar']">
      <img class="Comment__avatarImage" :src="author.picture" :alt="author.name" />
    </div>
    <div class="Comment__content">
      <div v-if="author && author.name" class="Comment__author">{{ author.name }}</div>
      <div class="Comment__message">
        {{ message }}
      </div>
      <div class="Comment__date">
        <Time :datetime="date" format="LLL" />
      </div>
    </div>
  </div>
</template>

<script>
import Time from '@components/Time';

export default {
  name: 'Comment',
  components: {
    Time,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    isAuthor: {
      type: Boolean,
      default: false,
    },
    author: {
      type: Object,
      default() {
        return {
          name: '',
          picture: '',
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Comment {
  font-size: 14px;
  display: flex;

  &.Comment--owner {
    flex-direction: row-reverse;
  }
  .Comment__avatar {
    width: 3em;
    height: 3em;
    border-radius: 100%;
    overflow: hidden;
    background: grey;
  }
  .Comment__avatarImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .Comment__content {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    flex: 0.7;
  }

  .Comment__message {
    padding: 0.5em 1em;
    border-radius: 0px 0.6em 0.6em 0.6em;
    background: $color-prim-grey;
    min-width: 180px;
    max-width: 600px;
    @media print {
      border: 1px solid black;
    }
  }
  &.Comment--owner .Comment__message {
    background: #bfbfbf;
    border-radius: 0.6em 0px 0.6em 0.6em;
  }
  .Comment__date {
    color: $color-off-grey;
    font-size: 0.8em;
    justify-content: flex-end;
  }

  &.Comment--owner .Comment__date {
    justify-content: flex-start;
  }

  .Comment__message {
  }
}
</style>
