<template>
  <div class="Comments">
    <CommentsList class="Comments" :comments="comments" :enable-input="true" />
  </div>
</template>

<script>
import helpers from '@helpers';
import { RmaStatus } from '@types/Rma';
import Textarea from '@components/Textarea';
import { Button, sizes as buttonSizes } from '@components/Buttons';

import CommentsList from '@scenes/SelfserviceScene/components/CommentsList';

export default {
  name: 'Comments',

  components: {
    CommentsList,
  },

  props: {
    comments: {
      requred: true,
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      newComment: '',
    };
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.CommentWrapper {
  width: 100%;
}
@mixin printStyles() {
  .ScreenOnly {
    visibility: hidden !important;
  }
}
@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
